import ArticleIcon from '~/src/components/generic/PhosphorIcons/ArticleIcon'
import MapTrifoldIcon from '~/src/components/generic/PhosphorIcons/MapTrifoldIcon'
import TruckIcon from '~/src/components/generic/PhosphorIcons/TruckIcon'
import UserGearIcon from '~/src/components/generic/PhosphorIcons/UserGearIcon'
import PackageIcon from '~/src/components/generic/PhosphorIcons/PackageIcon'
import CalendarDotIcon from '~/src/components/generic/PhosphorIcons/CalendarDotIcon'

import { verifyFeatureFlag } from '~/helpers/verifyFeatureFlag'

import { HomePage } from '~/__generated_types__/globalTypes'

import { Page } from '~/app/types'

const ryderShareAuthenticatedPages: Readonly<Array<Page>> = [
  {
    key: 'customerVisibility',
    path: '/rydershare/visibility',
    title: '(BETA)',
    logo: 'rydershare',
    subTitle: 'Visibility',
    isNavigable: true,
    isOnSidebar: true,
    icon: TruckIcon,
  },
  {
    key: 'customerVisibilityDetail',
    path: '/rydershare/visibility/:loadId',
    title: '',
    isNavigable: true,
    isOnSidebar: false,
    icon: TruckIcon,
  },
]

const tmCustomerAuthenticatedPages: Readonly<Array<Page>> = [
  {
    key: 'customerVisibilityDetail',
    path: '/rydershare/visibility/:loadId',
    title: '',
    isNavigable: true,
    isOnSidebar: false,
    icon: TruckIcon,
  },
  {
    key: 'shipments',
    path: '/shipments',
    title: 'Shipments',
    subTitle: 'Shipments',
    logo: 'shipments',
    isNavigable: true,
    isOnSidebar: verifyFeatureFlag(process.env.NEXT_PUBLIC_SHOW_SHIPMENTS_ON_NAVBAR),
    icon: PackageIcon,
  },
  {
    key: 'shipmentsDetail',
    path: '/shipments/:shipmentId',
    title: '',
    isNavigable: true,
    isOnSidebar: false,
    icon: PackageIcon,
  },
  {
    key: 'shipmentsLoadDetail',
    path: '/shipments/:shipmentId/load/:loadId',
    title: '',
    isNavigable: true,
    isOnSidebar: false,
    icon: PackageIcon,
  },
]

const operationalAuthenticatedPages: Readonly<Array<Page>> = [
  {
    key: 'visibility',
    path: '/visibility',
    title: 'Driver Visibility',
    logo: 'driverVisibility',
    subTitle: 'Driver Vis',
    isNavigable: true,
    isOnSidebar: true,
    icon: TruckIcon,
  },
  {
    key: 'loadboard',
    path: '/loadboard',
    title: 'Load Board',
    logo: 'loadBoard',
    subTitle: 'Load Board',
    isNavigable: true,
    isOnSidebar: true,
    icon: ArticleIcon,
  },
  {
    key: 'loadboardDetail',
    path: '/loadboard/:loadId',
    title: '',
    isNavigable: true,
    isOnSidebar: false,
    icon: ArticleIcon,
  },
  {
    key: 'location',
    path: '/location',
    title: 'Location Tool',
    subTitle: 'Location',
    isNavigable: true,
    isOnSidebar: verifyFeatureFlag(process.env.NEXT_PUBLIC_SHOW_LOCATION_ON_NAVBAR),
    icon: MapTrifoldIcon,
  },
  {
    key: 'planningBoard',
    path: '/planningboard',
    title: 'Planning Board',
    logo: 'loadBoard',
    subTitle: 'Planning',
    isNavigable: true,
    isOnSidebar: true,
    icon: CalendarDotIcon,
  },
]

const sysadminAuthenticatedPages: Readonly<Array<Page>> = [
  {
    key: 'admin-control-center',
    path: '/admin-control-center',
    title: 'Admin Control Center',
    icon: UserGearIcon,
    isOnSidebar: false,
    isNavigable: verifyFeatureFlag(process.env.NEXT_PUBLIC_SHOW_PERMISSION_PAGE),
  },
]

const DEFAULT_OPERATOR_HOME_PAGE = HomePage.Loadboard

export {
  ryderShareAuthenticatedPages,
  operationalAuthenticatedPages,
  sysadminAuthenticatedPages,
  tmCustomerAuthenticatedPages,
  DEFAULT_OPERATOR_HOME_PAGE,
}
